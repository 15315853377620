var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("c-tree-table", {
              attrs: {
                title: "BOM 목록",
                isTitle: true,
                parentProperty: "upEquipmentBomCd",
                customID: "equipmentBomCd",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                expandAll: true,
                hideBottom: true,
                isExcelDown: false,
                filtering: false,
                gridHeightAuto: true,
                gridHeight: _vm.grid.height,
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }