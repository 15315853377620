<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-tree-table
          title="BOM 목록"
          :isTitle="true"
          parentProperty="upEquipmentBomCd"
          customID="equipmentBomCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :gridHeightAuto="true"
          :gridHeight="grid.height"
        >
          <!-- @rowClick="rowClick" -->
        </c-tree-table>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="BOM 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && deleteable" label="추가" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && deleteable && isNotFirstRow"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable && isNotFirstRow"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-select
                  :required="true"
                  :editable="editable && dataeditable && isNotFirstRow"
                  codeGroupCd="EQUIPMENT_BOM_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="equipmentBomTypeCd"
                  label="구분"
                  v-model="data.equipmentBomTypeCd"
                ></c-select>
              </div>
              <div class="col-9">
                <c-text
                  v-if="!data.equipmentBomTypeCd"
                  :required="true"
                  :editable="false"
                  label="BOM 명"
                  name="equipmentBomName"
                  v-model="data.equipmentBomName">
                </c-text>
                <c-text
                  v-if="data.equipmentBomTypeCd == 'EBTC000001'"
                  :required="true"
                  :editable="editable && dataeditable && isNotFirstRow"
                  :disabled="!isEquipEdit"
                  :afterIcon="editable && dataeditable && isNotFirstRow ? [
                    { name: 'mode_edit', click: true, callbackName: 'equipEdit', color: '', tooltip: '수기입력' },
                    { name: 'search', click: true, callbackName: 'searchEquip', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeEquip', color: 'red' }
                  ] : null"
                  label="BOM 명"
                  name="equipmentBomName"
                  v-model="data.equipmentBomName"
                  @equipEdit="equipEdit"
                  @searchEquip="searchEquip"
                  @removeEquip="removeEquip">
                </c-text>
                <c-text
                  v-if="data.equipmentBomTypeCd == 'EBTC000002'"
                  :required="true"
                  :editable="editable && dataeditable && isNotFirstRow"
                  :disabled="!isEquipEdit"
                  :afterIcon="editable && dataeditable && isNotFirstRow ? [
                    { name: 'mode_edit', click: true, callbackName: 'equipEdit', color: '', tooltip: '수기입력' },
                    { name: 'search', click: true, callbackName: 'searchMaterial', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeEquip', color: 'red' }
                  ] : null"
                  label="BOM 명"
                  name="equipmentBomName"
                  v-model="data.equipmentBomName"
                  @equipEdit="equipEdit"
                  @searchMaterial="searchMaterial"
                  @removeEquip="removeEquip">
                </c-text>
              </div>
              <div class="col-4">
                <c-plant type="edit" :editable="editable && dataeditable && isNotFirstRow" :required="true" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <c-equip-bom
                  type="edit" 
                  :plantCd="param.plantCd" 
                  :equipmentCd="param.equipmentCd" 
                  :editable="editable && dataeditable && isNotFirstRow" 
                  label="상위 BOM" 
                  name="upEquipmentBomCd" 
                  v-model="data.upEquipmentBomCd" />
              </div>
              <div class="col-2">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable && isNotFirstRow"
                  label="순번"
                  name="sortOrder"
                  type="edit"
                  v-model="data.sortOrder">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div> -->
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  computed: {
    isEquipEdit() {
      return Boolean(!this.selectedBoms.equipmentBomConnCd && this.selectedBoms.equipmentBomName) || this.equipEditing
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      isNotFirstRow: false,
      equipEditing: false,
      grid: {
        columns: [
          {
            name: 'equipmentBomName',
            field: 'equipmentBomName',
            label: 'BOM명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'equipmentBomTypeName',
            field: 'equipmentBomTypeName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
        ],
        data: [],
        height: '',
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        equipmentCd: '',
      },
      data: {
        plantCd: null,  // 사업장코드
        equipmentBomCd: '',  // 설비별 BOM 일련번호
        equipmentCd: '',  // 설비번호
        equipmentBomTypeCd: null,  // BOM 구분
        equipmentBomName: '',  // BOM 명
        equipmentBomConnCd: '',  // BOM 연결코드(설비/자재)
        upEquipmentBomCd: '',  // 상위 BOM 코드
        sortOrder: '',  // 순번
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.bom.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedequipmentBomCd: '',
      selectedBoms: {},
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      this.listUrl = selectConfig.mdm.bom.list.url;
      this.detailUrl = selectConfig.mdm.bom.get.url;
      this.insertUrl = transactionConfig.mdm.bom.insert.url;
      this.updateUrl = transactionConfig.mdm.bom.update.url;
      this.deleteUrl = transactionConfig.mdm.bom.delete.url;
      
      this.grid.height = (window.innerHeight - 260) + 'px';

      this.searchParam.equipmentCd = this.param.equipmentCd;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getLocList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedequipmentBomCd = row.equipmentBomCd;
      this.selectedBoms = row;
      this.equipEditing = false;
      if (row.upEquipmentBomCd == '' || row.upEquipmentBomCd === null) {
        this.isNotFirstRow = false;
      } else {
        this.isNotFirstRow = true;
      }
      this.$http.url = this.$format(this.detailUrl, this.selectedequipmentBomCd);
      this.$http.param = {
        equipmentBomCd: this.selectedequipmentBomCd
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.isNotFirstRow = true;
      this.equipEditing = false;
      this.data = {
        plantCd: this.param.plantCd,  // 사업장코드
        equipmentBomCd: '',  // 설비별 BOM 일련번호
        equipmentCd: this.param.equipmentCd,  // 설비번호
        equipmentBomTypeCd: null,  // BOM 구분
        equipmentBomName: '',  // BOM 명
        equipmentBomConnCd: '',  // BOM 연결코드(설비/자재)
        upEquipmentBomCd: this.selectedBoms.equipmentBomCd,  // 상위 BOM 코드
        sortOrder: '',  // 순번
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.isNotFirstRow = true;
      this.selectedequipmentBomCd = '';
      this.equipEditing = false;
      this.selectedBoms = {};
      this.data = {
        plantCd: null,  // 사업장코드
        equipmentBomCd: '',  // 설비별 BOM 일련번호
        equipmentCd: '',  // 설비번호
        equipmentBomTypeCd: null,  // BOM 구분
        equipmentBomName: '',  // BOM 명
        equipmentBomConnCd: '',  // BOM 연결코드(설비/자재)
        upEquipmentBomCd: '',  // 상위 BOM 코드
        sortOrder: '',  // 순번
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.equipmentBomCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getLocList();
      } else {
        this.getLocList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getLocList();
    },
    equipEdit() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBL0000501',
        // 확인
        message: 'BOM을 수기로 작성할 시에\n\r설비/자재 마스터와의 연결이 제거됩니다.\n\r진행하시겠습니까?',
        type: 'info', // success / info / warning / error
        confirmCallback: () => {
          this.equipEditing = true;
          this.data.equipmentBomConnCd = null;
        },
        // 취소 callback 함수
        cancelCallback: () => {
          this.equipEditing = false;
        },
      });
    },
    searchEquip() {
      this.openEquipPopup();
    },
    openEquipPopup() {
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.equipEditing = false
        this.data.equipmentBomConnCd = data[0].equipmentCd
        this.data.equipmentBomName = data[0].equipmentName
      }
    },
    removeEquip() {
      this.equipEditing = false
      this.data.equipmentBomConnCd = null
      this.data.equipmentBomName = null
    },
    searchMaterial() {
      this.openMaterialPopup();
    },
    openMaterialPopup() {
      this.popupOptions.title = '자재 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMaterialPopup;
    },
    closeMaterialPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.equipEditing = false
        this.data.equipmentBomConnCd = data[0].materialCd
        this.data.equipmentBomName = data[0].materialName
      }
    },
  }
};
</script>
